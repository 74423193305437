@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    @font-face {
        font-family: GildaDisplay;
        src: url("./styles/fonts/GildaDisplay/GildaDisplay-Regular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: LibreBaskerville;
        src: url("./styles/fonts/LibreBaskerville/LibreBaskerville-Regular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: LibreBaskerville;
        src: url("./styles/fonts/LibreBaskerville/LibreBaskerville-Bold.ttf");
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: LibreBaskerville;
        src: url("./styles/fonts/LibreBaskerville/LibreBaskerville-Italic.ttf");
        font-style: italic;
        font-weight: normal;
    }

    @font-face {
        font-family: Merriweather;
        src: url("./styles/fonts/Merriweather/Merriweather-Black.ttf");
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: Merriweather;
        src: url("./styles/fonts/Merriweather/Merriweather-BlackItalic.ttf");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: Merriweather;
        src: url("./styles/fonts/Merriweather/Merriweather-Bold.ttf");
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: Merriweather;
        src: url("./styles/fonts/Merriweather/Merriweather-BoldItalic.ttf");
        font-weight: bold;
        font-style: italic;
    }

    @font-face {
        font-family: Merriweather;
        src: url("./styles/fonts/Merriweather/Merriweather-Italic.ttf");
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: Merriweather;
        src: url("./styles/fonts/Merriweather/Merriweather-Light.ttf");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: Merriweather;
        src: url("./styles/fonts/Merriweather/Merriweather-LightItalic.ttf");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: Merriweather;
        src: url("./styles/fonts/Merriweather/Merriweather-Regular.ttf");
        font-weight: normal;
        font-style: normal;
    }


    @font-face {
        font-family: PtSerif;
        src: url("./styles/fonts/PtSerif/PTSerif-Bold.ttf");
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: PtSerif;
        src: url("./styles/fonts/PtSerif/PTSerif-BoldItalic.ttf");
        font-weight: bold;
        font-style: italic;
    }

    @font-face {
        font-family: PtSerif;
        src: url("./styles/fonts/PtSerif/PTSerif-Italic.ttf");
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: PtSerif;
        src: url("./styles/fonts/PtSerif/PTSerif-Regular.ttf");
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: Noto;
        src: url("./styles/fonts/Noto/NotoSerif-VariableFont_wdth,wght.ttf");
        font-weight: 100 900;
        font-style: normal;
    }

    @font-face {
        font-family: Noto;
        src: url("./styles/fonts/Noto/NotoSerif-Italic-VariableFont_wdth,wght.ttf");
        font-weight: 100 900;
        font-style: italic;
    }

    @font-face {
        font-family: Lora;
        src: url("./styles/fonts/Lora/Lora-VariableFont_wght.ttf");
        font-weight: 100 900;
        font-style: normal;
    }

    @font-face {
        font-family: Lora;
        src: url("./styles/fonts/Lora/Lora-Italic-VariableFont_wght.ttf");
        font-weight: 100 900;
        font-style: italic;
    }

    @font-face {
        font-family: PlayfairDisplay;
        src: url("./styles/fonts/PlayfairDisplay/PlayfairDisplay-VariableFont_wght.ttf");
        font-weight: 100 900;
        font-style: normal;
    }

    @font-face {
        font-family: PlayfairDisplay;
        src: url("./styles/fonts/PlayfairDisplay/PlayfairDisplay-Italic-VariableFont_wght.ttf");
        font-weight: 100 900;
        font-style: italic;
    }

    @font-face {
        font-family: ClassyCoiffeur;
        src: url("./styles/fonts/ClassyCoiffeur/Classy Coiffeur Regular.otf");
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: ClassyCoiffeur;
        src: url("./styles/fonts/ClassyCoiffeur/Classy Coiffeur Light.otf");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: ClassyCoiffeur;
        src: url("./styles/fonts/ClassyCoiffeur/Classy Coiffeur Bold.otf");
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: NotoDisplay;
        src: url("./styles/fonts/NotoDisplay/NotoSerifDisplay-VariableFont_wdth,wght.ttf");
        font-weight: 100 900;
        font-style: normal;
    }

    @font-face {
        font-family: NotoDisplay;
        src: url("./styles/fonts/NotoDisplay/NotoSerifDisplay-Italic-VariableFont_wdth,wght.ttf");
        font-weight: 100 900;
        font-style: italic;
    }

    @font-face {
        font-family: BodoniModa;
        src: url("./styles/fonts/BodoniModa/BodoniModa-VariableFont_opsz,wght.ttf");
        font-weight: 100 900;
        font-style: normal;
    }

    @font-face {
        font-family: BodoniModa;
        src: url("./styles/fonts/BodoniModa/BodoniModa-Italic-VariableFont_opsz,wght.ttf");
        font-weight: 100 900;
        font-style: italic;
    }
}

@layer utilities {
    .fadeIn {
        opacity: 0;
        animation-fill-mode: forwards;
    }
    .delay-0 {
        animation-delay: 0ms;
    }
    .delay-500 {
        animation-delay: 500ms;
    }
    .delay-1000 {
        animation-delay: 1000ms;
    }
    .delay-1500 {
        animation-delay: 1500ms;
    }
    .delay-2000 {
        animation-delay: 2000ms;
    }
    .delay-2500 {
        animation-delay: 2500ms;
    }
    .delay-3000 {
        animation-delay: 3000ms;
    }
    .delay-3500 {
        animation-delay: 3500ms;
    }
    .delay-4000 {
        animation-delay: 4000ms;
    }
    .delay-4500 {
        animation-delay: 4500ms;
    }
    .image-zoom-transition {
        transition: transform 0.5s ease-in-out;
    }
    .image-zoomed:hover {
        transform: scale(1.1);
    }
}